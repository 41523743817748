import React, { useContext, useEffect, useState } from "react";
import PopupManager from "../PopupManager.js";
import Popup from "./Popup.js";
import FormatText from "./FormatText.js";
import TCaption from "./TCaption.js";
import GTMClassWrapper from "./GTMClassWrapper";
import CheckList from "./CheckList.js";

import "../css/TestDrive.scss";
import ShowMoreButton from "./ShowMore/button";
import ShowMoreContent from "./ShowMore/content";
import GlobalContext from "../GlobalContext";

const FormMaterial = React.lazy(() => import("./FormMaterial.js"));

const TestDriveV5 = (props) => {
  const { products, togglePrivacyAgree } = useContext(GlobalContext);
  const [tdProducts, setTdProducts] = useState([]);
  const [formFields, setFormFields] = useState([
    {
      type: "text",
      name: "username",
      label: "Ваше имя",
      placeholder: "Имя",
      required: true,
    },
    {
      type: "phone",
      name: "phone",
      label: "Ваш номер телефона",
      placeholder: "Телефон",
      required: true,
    },
  ]);

  let category = "";
  let target = "";

  if (props.product) {
    const condition = props.product.sections
      ? props.product.sections[0]
      : props.product.section;
    switch (condition) {
      case "fitness":
        {
          category = "тренажёров";
          target = "подходящий тренажёр";
        }
        break;
      case "chairs":
        {
          category = "массажных кресел";
          target = "подходящее массажное кресло";
        }
        break;
      default: {
        category = "массажёров";
        target = "подходящий массажёр";
      }
    }
  }

  let hint = "";

  if (props.showMore) {
    hint = "курьер привезёт товар домой";
  } else {
    hint = <span>наш менеджер пригласит вас в&nbsp;ближайший шоу-рум</span>;
  }

  const getProductByCategory = (category) => {
    return Object.keys(products)
      .filter((product) => {
        const typeTd = props.showMore ? 190 : 189;

        let result = products[product].type_test_drive === typeTd;

        if (category !== "all") {
          result = products[product].sections.includes(category);
        }

        return result;
      })
      .map((product) => {
        return {
          label: products[product].name,
          value: products[product].id,
        };
      })
      .sort((a, b) => {
        const bandA = a.label.toUpperCase();
        const bandB = b.label.toUpperCase();

        let comparison = 0;

        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }

        return comparison;
      });
  };
  useEffect(() => {
    if (props.withProductList) {
      const category = props.categoryProductList
        ? props.categoryProductList
        : "all";
      const filtered = getProductByCategory(category);

      setTdProducts(filtered);

      setFormFields((prev) => [
        ...prev,
        {
          type: "select",
          name: "product_id",
          label: "Выберите товар",
          placeholder: "Выберите товар",
          required: true,
          options: [{ label: "", value: null }, ...filtered],
        },
      ]);
    }

    if (props.product && !props.withProductList) {
      setFormFields((prev) => [
        ...prev,
        {
          type: "hidden",
          name: "product_id",
          value: props.productId ? props.productId : props.product.id,
        },
      ]);
    }
  }, []);

  if (!!props.product && !!props.product.available)
    return (
      <div className="__TestDriveV5">
        <div className="testdrive-box">
          <div className="top-wrapper">
            <div className="testdrive-title">Тест-драйв</div>
            <div className="testdrive-text">
              Запишитесь <br />
              на&nbsp;бесплатный тест-драйв <br />
              {props.customTitle ? (
                <span> {props.customTitle}</span>
              ) : (
                <span style={{ fontWeight: "600", whiteSpace: "nowrap" }}>
                  {" "}
                  {props.product.name}
                </span>
              )}
            </div>
            <PopupManager>
              <Popup
                popupId="popup-testdrive"
                hook
                show={false}
                backdrop
                animation
                gtmEvent={"gtm_popup_open_testdivezapis"}
                closeButton={false}
                button={
                  <GTMClassWrapper className="gtm_btn_testdrivezapis">
                    <div className="testdrive-button">
                      <span>Записаться</span>
                    </div>
                  </GTMClassWrapper>
                }
              >
                {{
                  header: "",
                  body: (
                    <>
                      <div className="hidden-on-result">
                        <FormatText type="text-title">
                          Бесплатный тест-драйв {category}
                        </FormatText>
                        <FormatText type="text-sub">
                          Запишитесь на&nbsp;тест-драйв, чтобы сравнить
                          и&nbsp;выбрать {target}.<br />
                          Заполните форму&nbsp;и {hint}.
                        </FormatText>
                      </div>
                      <React.Suspense fallback={<></>}>
                        <FormMaterial
                          formId="form-testdrive"
                          submitText={
                            <div className="submit-button-red">
                              Записаться на тест-драйв
                            </div>
                          }
                          submitClass="gtm_btn_popupform_testdrive"
                          submitUrl={`https://dev.casada-russia.ru/api/bitrix/form/testdrive_order`}
                          method="POST"
                          parentPopup="popup-testdrive"
                        >
                          {formFields}
                        </FormMaterial>
                      </React.Suspense>

                      <div className="hidden-on-result">
                        <FormatText type="text-small">
                          Записываясь на тест-драйв, вы даете согласие на{" "}
                          <span
                            onClick={togglePrivacyAgree}
                            className="link-policy hl-red"
                          >
                            обработку персональных данных
                          </span>
                        </FormatText>
                      </div>
                    </>
                  ),
                  footer: "",
                  cancel: {
                    // text: "",
                    // callback: handleCookieDecline
                  },
                  accept: {
                    // text: "",
                    // callback: handleCookieAccept
                  },
                }}
              </Popup>
            </PopupManager>

            {props.showMore && (
              <>
                <TCaption.Spacer height="25px" />
                <ShowMoreButton
                  ShowMoreContent
                  id="testdrive5"
                  hideOnOpen
                  showText="Подробнее"
                  textCenter
                  textWhite
                />
              </>
            )}
          </div>
          {props.showMore ? (
            <div className="caption">
              <ShowMoreContent id="testdrive5" hideText="Свернуть" textCenter>
                <TCaption.Wrapper left white>
                  <TCaption.Text>
                    Курьер бесплатно привезет интересующий массажёр домой
                    в&nbsp;удобное время. Вы&nbsp;сможете попробовать массажёр
                    и&nbsp;получить ответы на&nbsp;все интересующие вопросы.
                    Если товар понравился, оформляйте покупку.
                  </TCaption.Text>
                  <TCaption.Spacer height="20px" />
                  <TCaption.Sub>Сколько стоит?</TCaption.Sub>
                  <TCaption.Text>
                    Тест-драйв&nbsp;&mdash; абсолютно бесплатная услуга.
                    Вы&nbsp;не&nbsp;платите за&nbsp;услуги курьера независимо
                    от&nbsp;того покупаете вы&nbsp;массажер или нет.
                  </TCaption.Text>
                  <TCaption.Spacer height="20px" />
                  <TCaption.Sub>Где можно попробовать?</TCaption.Sub>
                  <TCaption.Text>
                    Список городов, где доступен Тест-драйв:
                  </TCaption.Text>
                  <TCaption.Spacer height="20px" />
                  <CheckList.Wrapper>
                    <CheckList.Item>Москва в пределах МКАД</CheckList.Item>
                    <CheckList.Item>Архангельск</CheckList.Item>
                    <CheckList.Item>Воронеж</CheckList.Item>
                    <CheckList.Item>Екатеринбург</CheckList.Item>
                    <CheckList.Item>Рязань</CheckList.Item>
                    <CheckList.Item>Красноярск</CheckList.Item>
                    <CheckList.Item>Томск</CheckList.Item>
                    <CheckList.Item>Абакан</CheckList.Item>
                  </CheckList.Wrapper>
                  <TCaption.Spacer height="20px" />
                  <TCaption.Sub>Можно ли купить сразу?</TCaption.Sub>
                  <TCaption.Text>
                    Курьер берёт на&nbsp;тест-драйв только демонстрационные
                    образцы, которые не&nbsp;продаются. Если после тест-драйва
                    вы&nbsp;хотите оформить покупку, то&nbsp;можно сделать это
                    одним из&nbsp;следующих способов:
                  </TCaption.Text>
                  <TCaption.Text>непосредственно у курьера;</TCaption.Text>
                  <TCaption.Text>оформить заказ на сайте;</TCaption.Text>
                  <TCaption.Text>
                    позвонить по телефону 8 800 600 41 50
                  </TCaption.Text>
                </TCaption.Wrapper>
              </ShowMoreContent>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  else {
    return <div></div>;
  }
};

export default TestDriveV5;
